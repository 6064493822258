@import url('https://fonts.googleapis.com/css2?family=Roboto+Serif:ital,opsz,wght@0,8..144,100..900;1,8..144,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,100..1000&family=Roboto+Serif:ital,opsz,wght@0,8..144,100..900;1,8..144,100..900&display=swap');

:root {
  --main-color: #E44796; 
  --gradient-pink: linear-gradient(0deg, #E44796, #F3C3D8);
    --gradient-blue: linear-gradient(0deg, #09365F , #5F8AFA );
    --gradient-light: linear-gradient(0deg, #FFF9DD , #FED892 );

}

body,
html {
  font-size: 16px;
  overflow-x: hidden;
  font-family: "Roboto Flex", sans-serif;
  color : #333333
}

a {
  color: inherit !important;
  text-decoration: none !important;
  color: inherit !important;
}
.logo img {
  width: 100%;
  margin: auto;
  display: block;
  max-width: 100%;
}
.highlight-blk{
  background: var(--gradient-pink);
  color: #fff;
  margin-bottom: 0;
  text-align: center;
  overflow: hidden;
}
.header {
  background: linear-gradient(180deg,#ffffff,#cccccc78);
}
/* .highlight-blk img{
  width: 50px;
} */
.highlight-blk p{
  margin-bottom: 0;
}
.highlight-blk marquee p {
  margin-bottom: 0;
  font-size: 15px;
}
.highlight-blk marquee p a{margin-right:10px;border-right: solid 1px #fff;padding-right:10px;}
.header-contact {
  vertical-align: middle;
  /* height: 100%; */
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 26px;
  padding-bottom: 20px;
}
.header-contact >div  img {
height: 20px;
}
.pd-0{
padding: 0;
} 
.highlight-blk a img {
width: 33px;
}
.header-contact h3 {
  font-size: 20px;
  font-weight: 500;
  color: var(--main-color);
}
.header-contact h3 svg {
  margin-right: 10px;
}
.header-contact > div {
margin-bottom: 0;
width: 40%;
text-align: center;
}
.header-contact  .border-tab{  
  background: var(--gradient-pink);
  padding: 9px 14px;
  border-radius: 16px;
  margin-bottom: 0;
  transition: 0.5s;
  color: #444444;
  cursor: pointer;
  box-shadow: 0px 20px 18px -10px #0000003d;
  text-transform: capitalize;
  line-height: 21px;
}
/* .border-tab:hover{
background: var(--gradient-red);
} */
.d-flex{
display: flex;
}
.space-even{
  justify-content: space-evenly;
  padding: 0px 40px;
}
.col-md-3.highlight-tab .space-even a img {
width: 16px !important; 

} 
.menu {
width: 100%;
float: left;
height: 0;
}
.border-tab{
position: relative;
} 
 
.menu-toggle {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  display: none;
  
}
p.d-flex.space-even.highlight-blk:before {
  position: absolute;
  content: "";
  width: 70px;
  height: 70px;
  background: #f9f9f9;
  left: -50px;
  top: -13px;
  transform: rotate(56deg);
}

.submenu {
  list-style-type: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;
  width: 100%;
  background: var(--gradient-blue);
  margin: auto;
  z-index: 999;
  position: relative;
  border-radius: 5px;
  padding: 16px;
}
.submenu li:last-child {
  border-right: 0;
}
.submenu li {
  padding: 0 20px;
  position: relative;
  width: max-content;
  color: #fff;
  margin: 0 0px;
  font-weight: 500;
  text-transform: uppercase;
  border-right: 1px solid #ffffff3b;
  font-size: 15px;
  cursor: pointer;
  font-family: "Roboto Serif", serif;
}

.submenu li:last-child {
  border-bottom: none;
}

.menu:hover .submenu {
  display: flex;
}

.sub-submenu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0%;
  background-color: #fff; 
  padding-left: 0;
  list-style-type: none;    
  padding: 0px 10px;
  box-shadow: 0px 0px 9px #bfbfbf;
  border-radius: 2px;
}
.sub-submenu li {
  color: #000;
  font-size: 15px;
  border-right: 0;
  padding: 7px 0px;
}
.sub-submenu li:hover{
color: #245190;
cursor: pointer;
}

.submenu li:hover .sub-submenu {
  display: block;
}
 
.sub-submenu li{
  color: #000;
  text-transform: capitalize;
  font-family: "Manrope", font-family;
}
.submenu li i.bi.bi-chevron-down {
  margin-left: 5px;
  font-size: 11px;
}
.header-contact .border-tab a {
position: relative;
}
.contact-tab {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.contact-tab img {
  display: block;
  margin: auto;
  width: 47px;
  border: 2px solid #275395;
  border-radius: 50%;
  padding: 8px;
}

.contact-tab p {
  font-size: 15px;
}

.contact-tab p a {
  font-size: 13px;
  display: block;
  color: #333333  !important;
  text-transform: capitalize;
  line-height: 16px;
  font-family: "Roboto Flex", sans-serif;
}

.contact-tab p {

  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 0;
  font-family: "Roboto Serif", serif;
  transition: all 0.3s ease-in;
}
.contact-tab p b{
  -webkit-text-fill-color: #0000;
  background: var(--gradient-blue);
  -webkit-background-clip: text;
  font-weight: 600 !important;
}

.contact-tab p a span {
  color: #666666;
  text-transform: lowercase;
}

.contact-tab > div {
  margin: 0 20px;
}

 
.breadcrumb-blk h2 {
  font-family: Neue-Aachen-Pro;
  font-size: 40px;
}

p {
  font-size: 15px;
  line-height: 24px;
}

ul li {
  line-height: 29px;
  font-size: 16px;
  margin-bottom: 10px;
}

.about-us img.img-fluid {
  width: 100%;
}



.highlight-tab {
  background: var(--gradient-red); 
  position: relative;
}

.highlight-tab a {
  padding: 1px;
  padding-bottom: 3px;
}

.header-contact .border-tab {
  position: relative;
  font-family: "Roboto Serif", serif;
  font-weight: 600;
} 
.header-blk {
  padding: 8px;
  width: 90%;
  margin: auto;
}
.vision-mission .mission, .vision-mission .vission, .vision-mission .motto, .vision-mission .highlight{
  background: var(--gradient-blue);
  padding: 20px;
  border-radius: 16px;
  color: #fff;
  box-shadow: 3px 30px 20px -20px #626262;
  width: 95%;
  margin-top: -110px;
  min-height: 304px;
}
.vision-mission .highlight marquee {
  height: 198px;
}
.vision-mission .highlight{
  color: #333333;
background: var(--gradient-light);
}
.vision-mission .highlight a{
  background: var(--gradient-blue);
}
.vision-mission .mission span, .vision-mission .vission span, .vision-mission .motto span {
  font-weight: 600;
}
.vision-mission .col-md-6 {
  padding: 0;
}
.vision-mission h3 img {
  width: 50px;
}

.vision-mission .mission ul li {
  margin-bottom: 0;
  position: relative;
  padding-left: 20px;
}
.vision-mission .mission ul li:before {
  width: 0;
  content: "";
  height: 0;
  border-top: 5px solid #0000;
  border-left: 10px solid #e54d99;
  border-right: 0px solid #0000;
  border-bottom: 5px solid #0000;
  position: absolute;
  left: 0;
  top: 10px;
}
.vision-mission .mission ul {
  padding-left: 0;
  padding-left: 0px;
  list-style: none;
}

.vision-mission .mission p {
  margin-bottom: 0;
}
.vision-mission h3 {
  text-align: center;
  margin-top: 0;
  font-size: 18px;
  font-weight: normal; 
  text-transform: uppercase;
  font-family: "Roboto Serif", serif;
  font-weight: 600;
}
.vision-mission .highlight marquee p {
  display: flex;
  align-items: center;
  line-height: 22px;
}

.vision-mission .highlight marquee p a {
  margin-left: 10px;
  padding: 0 5px;
  border-radius: 5px;
}

.bg-image{
  position: relative;
  z-index: 1;
}
.bg-image:before{
  content: "";
  width: 50%;
  z-index: -1;
  height: 300px;
  position: absolute;
  left: 0;
  top: 0;
  background: url(https://webapi.entab.info/api/image/SMSUM/public/Images/lotus.png);
  background-repeat: no-repeat;
}
.bg-image:after{
  content: "";
  width: 50%;
  height: 300px;
  position: absolute;
  right: 0;
  top: 0;
  background: url(https://webapi.entab.info/api/image/SMSUM/public/Images/lotus.png);
  background-repeat: no-repeat;
  background-position: right;
  z-index: -1
}

.title {
  text-align: center;
  margin-bottom: 30px;
  color: var(--main-color);
  display: flex;
  align-items: center;
  text-transform: uppercase;
}

.title .title-gif {
  width: max-content;
  margin: auto;
  border-bottom: 4px solid #f76d6d;
  padding: 2px 10px;
}

.title .title-gif img {
  width: 55px;
}
 




.button a {
  position: relative;
  /* margin-left: auto; */
  display: block;
  /* margin: auto; */
  width: max-content;
  /* padding: 8px 10px; */
  /* border-radius: 12px; */
  /* background: #fff; */ 
} 
.upcoming h4{
  width: max-content;
  margin: auto;
  color: var(--main-color);
  position: relative;
  font-size: 19px;
  letter-spacing: 2px;
}
.upcoming h4:before {
  content: "";
  width: 80px;
  position: absolute;
  top: 120%;
  background: #24508f;
  height: 3px;
  transform: translate(-50%, -50%);
  left: 50%;
}
.abt-button{
  position: relative;
  transition: 0.5s;
}
.button.abt-button:hover {
  margin-top: -5px;
}
.header-contact .border-tab:before, .abt-button:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 100%;
  background: url(https://webapi.entab.info/api/image/SMSUM/public/Images/head-ref.png);
  left: 0;
  background-size: 100% 100%;
  transition: 0.5s;
}
.abt-button:before{  
  background: url(https://webapi.entab.info/api/image/SMSUM/public/Images/Read-more-before.png);
  background-size: 100% 100%;
  transform: unset;
  background-repeat: no-repeat;
  background-position: top center;
  top: 86%;
  z-index: 9;


}
.view-gal:before{  
  background: url(https://webapi.entab.info/api/image/SMSUM/public/Images/Read-more-before.png);
  background-size: 100% 100%; 
  top: 100%;
  background-repeat: no-repeat;
}
.header-contact .border-tab:hover:before {
  top: 105%;
}

p.border-tab:hover {
  margin-top: -10px;
}
.ml-0{
  margin-left: 0 !important
}

.button {
  position: relative;
  width: max-content; 

} 
p span {
  font-weight: 600;
}

.button a:hover {
  background: var(--gradient-red);
}

.min-ht {
  min-height: 600px;
}

.about-us {
  margin-top: -40px;
  position: relative;
  padding: 130px 0 40px;
}

.about-us .title {
  margin-bottom: 4px !important;
}

.about-us>div {
  position: relative;
  z-index: 9;
}
 

.news {
  width: 100%;
  margin: auto;
  padding: 30px 30px;
  border-radius: 6px;
  background: url(https://webapi.entab.info/api/image/SMSUM/public/Images/news-bg.png);
  background-size: 100% 100%;
  padding-bottom: 70px;
}

.news-blk marquee {
  min-height: 210px;
  height:380px;
}.title.center {
  justify-content: center;
}

.newsarea {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.news .button a {
  margin: auto;
} 

.news-event {
  margin-top: 40px;
  position: relative;
}
.news-event:before{
  content: "";
  width: 50%;
  height: 100%;
  position: absolute;
  left: 0%;
  z-index: -1;
  top: 50%;
  transform: translate(-0%, -50%);
  background: url(https://webapi.entab.info/api/image/SMSUM/public/Images/event-bg1.png);
  background-repeat: no-repeat;
}
.news-event:after{
  content: "";
  width: 50%;
  height: 100%;
  top: 50%;
  position: absolute;
  right: 0%;
  z-index: -1;
  transform: translate(-0%, -50%);
  background: url(https://webapi.entab.info/api/image/SMSUM/public/Images/event-bh2.png);
  background-repeat: no-repeat;
  background-position: right;
}
.news-event>div{
  width: 90%;
  margin: auto;
}
.events-blk .button {
  margin-top: 20px;
}

.events {
  width: 90%;
  margin: auto;
  background: url(https://webapi.entab.info/api/image/SMSUM/public/Images/events-bg.png);
  background-size: 100% 100%;
  padding: 30px 30px;
  border-radius: 6px; 
  padding-bottom: 70px;
}

.evt-img {
  position: relative;
}
.evt-img img{height:260px;object-fit: contain;border-radius: 11px;    width: 100%; background: #ddd}

.evt-img .date {
  position: absolute;
  right: 4px;
  top: 4px;
  background: var(--primary-color);
  color: #fff;
  text-align: center;
  padding: 4px 8px;
  border-radius: 9px;
  font-size: 13px;
}

.evt-title {    
  
  padding: 8px 13px;
  border-radius: 10px;
}

.evt-title h3 {
  margin: 0;
  font-size: 21px;
  text-transform: uppercase;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-align: center;
  margin-top: 12px;
  font-family: "Roboto Serif", serif;
}

.evt-img .date span {
  display: block;
}

.evt-blk {
  background: #fff;
  padding: 6px;
  border-radius: 16px;
  margin: 0px 5px;
}

.evt-blk p img {
  width: 14px !important;
  float: left;
  vertical-align: middle;
  margin-top: 8px;
  margin-right: 7px;
}

.evt-blk p span {
  float: right;
  color: #666666;
  font-weight: normal;
}

.evt-content {
  padding: 30px 20px 2px;padding-top:10px;
}

.evt-content p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  color: #666666;
}

.evt-content p:last-child {
  font-size: 14px;
}
.news-event .title {
  justify-content: center;
}

.news-event .title> div:nth-child(1) {
  width: 90%;
}
.newsarea>div {
  width: 80%;
}
.newsarea p {
  margin-bottom: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.newsarea a {
  background: var(--gradient-blue);
  color: #fff !important;
  padding: 5px 10px;
  border-radius: 9px 10px;
}
.news-event .title> div:nth-child(2) {margin-right: 0;padding: 0 2px;}
 
 

.message {
  width: 90%;
  padding: 100px 0;
  margin: auto;
}


 #myImg, #myImg1, #myImg2 {
  width: 100%;
  border: 5px solid rgba(158, 158, 158, 0.1);
  background: #eaeaea;
  cursor: pointer;
  transition: 0.3s;
}
.erp_sec img { 
  display: inline-block;
}
.erp_sec h4.imgheading {
  text-align: center;
}
.assistance {
  background: #eaeeff;
  padding: 20px;
  margin-top: 30px;
}
 

.facilities-main {  
  background: url(https://webapi.entab.info/api/image/SMSUM/public/Images/facility-bg.png);
  background-size: 100% 100%;
}
.facilities {
  width: 50%;
  padding: 100px 0;
  margin: auto;
}

.faci-blk {
  width: 80%;
  margin: auto;
  text-align: center;
  background: #eaeaea;
  padding: 40px 20px;
  border-radius: 7px;
  transition: 0.5s;
}

.faci-blk:hover {
  box-shadow: 0px 0px 20px 0px #c5c5c5;
}

.faci-blk img {
  height: 70px;
  margin-bottom: 20px;
}

.faci-blk p {
  font-size: 18px;
  margin-bottom: 0;
  font-weight: 600;
}
.message-block {
  display: flex;
  text-align: center;
}
.message-content p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 10;
  overflow: hidden;
}
.message-block .button {
  margin: auto;
  transform: translateY(10px);
  margin-top: 100px;
}
.message-block .button:hover{
  transform: translateY(-10px);
  margin-top: 100px;
}
.message-image-scd img {
  position: absolute;
  right: 10px;
  height: auto !important;
  top: 10px;
  width: 80px !important;
}
.message-image-first {
  position: relative;
}
.princi-Name {
  background: var(--gradient-blue);
  color: #fff;
  text-align: center;
  padding: 10px 0 10px;
  width: 95%;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, -9%);
  position: absolute;
    text-transform: uppercase;
}

.princi-Name h3 {
  font-size: 21px;
  font-family: "Roboto Serif", serif;
} 
.princi-Name p {
  margin-bottom: 0;
  font-size: 22px;
}
.message-block > div:nth-child(1) {
  width: 70%;
}

.message-block > div:nth-child(2) {
  width: 30%;
  margin-left: 30px;
  position: relative;
}
.message-image-first img {
  max-height: 450px;
  height: 450px;
  object-fit: cover;
  object-position: center;
}
.message-title{
  text-align: center;
  background: var(--main-color);
  color: #fff;
  padding: 5px;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-family: "Roboto Serif", serif;
  font-size: 21px;
  letter-spacing: 1px;
}

.birthday-block{
  background: url(https://webapi.entab.info/api/image/SMSUM/public/Images/birthday-bg.png);
  background-size: 100% 100%;
  padding: 60px 10px 100px; 
}
.message-blk{
  background: url(https://webapi.entab.info/api/image/SMSUM/public/Images/message-bg.png);
  background-size: 100% 100%;
    padding: 30px 30px 60px;
}
.birthday-block .birthday-theme {
  width: 70%;
  margin: auto;
}

.birthday-block .birthday-theme img {
  width: 80px !important;
  margin: auto;
  margin-bottom: 10px;
  border: 3px solid #e44796;
  border-radius: 50px;
}
.birthday-content {
  width: 100%;
  text-align: center;
}

.birthday-content img {
  margin-bottom: 0;
  line-height: 20px;
  font-size: 13px;
  width: 70px;
}

.birthday-content p {
  margin-bottom: 0;
  line-height: 20px;
  font-size: 16px;
  font-weight: 600;
  font-family: "Roboto Serif", serif;
}
.upcoming {
  text-align: center;
  padding-right: 80px;
  margin-bottom: 40px;
  text-transform: uppercase;
}  
.birthday-content p.primary {
  font-weight: normal;
  font-size: 13px;
}
 .addColor h4{
  color: #333333 !important;
  letter-spacing: 1px;
 }

.birth-topper { 
  margin-top: 40px;
  width: 100%; 
  padding: 60px auto 40px;
  background: url(https://webapi.entab.info/api/image/SMSUM/public/Images/birthday-main-bg.png);
  background-size: 100% 100%;
}
.birthday-flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.birthday-flex >  div {
  width: 30%;
  margin-bottom: 20px;
}

.topper-img img {
  width: 110px !important;
  margin: auto;
}

.topper-img {
  background: url(https://webapi.entab.info/api/image/SMSUM/public/Images/topper-bg.png);
  
  padding: 43px 24px;
  background-position: center;
  background-repeat: no-repeat;
}

p.percent {
  text-align: center;
  width: 54px;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--gradient-pink);
  border-radius: 50%;
  color: #fff;
  margin: auto;
  margin-bottom: 10px;
}

.topper-cont {
  text-align: center;
  margin-bottom: 20px;
}

.topper-cont p {
  margin-bottom: 4px;
}

.tpr-block {
  position: relative;
  padding-left: 60px;
  background: linear-gradient(181deg, #ffffffb0, #ffffff52);
  margin-bottom: 12px;
}

.tpr-class-img {
  position: absolute;
  left: -40px;
  top: -50px;
}
.gallery:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: url(https://webapi.entab.info/api/image/SMSUM/public/Images/gallery-before.png);
  background-size: auto 100%;
}
.gallery-btn-block{
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gallery {
  padding: 30px 0;
  position: relative;
}
.gallery .button {
  margin: auto;
  margin-top: 00px;
}
.gallery-main{
  background: url(https://webapi.entab.info/api/image/SMSUM/public/Images/gallery-bg.png);
  background-size: 100% 100%;
  padding-bottom: 40px;
  padding-top: 30px;
}
.gallery-blk img {
  height: 240px;
  /* object-fit: contain; */
  /* background: #ddd; */
  width: 100%;

}

.gallery-blk {
  position: relative;
  margin-bottom: 20px;
}

.overlay {
  position: absolute;
  background: #0000009c;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 90%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: #fff;
  opacity: 0;
  transition: 0.5s;
}
.overlay:hover {
  opacity: 1;
}

.overlay h3 {
  font-size: 18px;
  margin-bottom: 0;
  /* font-weight: 600; */
  margin-top: 0;
   font-family: "Roboto Serif", serif;
   display: -webkit-box;
   -webkit-box-orient: vertical;
   -webkit-line-clamp: 2;
   overflow: hidden;
}

.overlay p {
  font-weight: 200;
  margin-bottom: 0;
}
.footer {
  width: 90%;
  margin: 60px auto 40px;
}
.app-cont p {
  font-size: 15px;
  line-height: 21px;
  text-align: center;
    font-family: "Roboto Serif", serif;
}

.footer {
  margin-bottom: 0;
}
.quick-link h3 , .cont-dtl h3{
  font-family: "Roboto Serif", serif;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 18px;
}

.inr-event-img img{    height: 250px;
  object-fit: contain;}
  .title img {margin-left: 17px;}
  .upcoming img {
    width: 50px;
}
  .title p {
      margin-bottom: 0;
      letter-spacing: 2px;
  }
  .change-title h3 {
    text-align: center;
    font-size: 24px;
    font-family: "Roboto Serif", serif;
    color: #1f4c87;
}
.title h3 {
  font-size: 31px;
  margin-top: 0; 
  -webkit-text-fill-color: #0000; 
  background: var(--gradient-blue);
  -webkit-background-clip: text;
  font-family: "Roboto Serif", serif;
  font-weight: 700;
}
.quick-link ul {
  display: flex;
  flex-direction: column;
  height: 200px;
  flex-wrap: wrap;
}
.cont-dtl img {
  width: auto !important;
}
.cont-dtl p {
  display: flex;
  font-size: 14px;
  line-height: 23px;
  flex-direction: row;
  align-items: center;
}
.cont-dtl p span {
  font-weight: normal;
}
.cont-dtl p img {
  margin-right: 21px;
}

.quick-link ul li {
  width: 50%;
}
footer {
  background: url(https://webapi.entab.info/api/image/SMSUM/public/Images/footer.png);
  background-size: 100% 100%;
  padding-top: 10px;
  padding-left: 200px;
}

.footer>div img {
  width: 330px;
}

.footer iframe {
  height: 200px;
  border: 5px solid #fff;
  box-shadow: 1px 2px 5px 0px #646464;
}

.app-cont {
  width: 70%;
}

.app-cont img {
  width: 110px !important;
}

.phone-img img {
  width: 100% !important;
}

.phone-img {
  width: 30%;
}

.quick-link ul {
  padding: 0 0px;
}

l {
  position: relative;
  list-style: none;
}

.quick-link ul li:before {
  left: 0;
  content: "";
  width: 11px;
  height: 21px;
 background: url(https://webapi.entab.info/api/image/SMSUM/public/Images/footer-icon.png);
 background-repeat: no-repeat;
  position: absolute;
  border-radius: 2px;
  top: 11px;
}

.quick-link ul li {
  padding-left: 21px;
  list-style: none;
  position: relative;
  padding: 3px 20px;
  font-size: 16px;
  margin-bottom: 0;
}

.footer .d-flex {
  align-items: flex-end; 
}

.footer .link {
  background: var(--main-color);
  color: #fff;
  padding: 5px 10px;
  border-radius: 8px;
  margin-top: 16px;
  width: max-content;
  max-width: 100%;
}

.copyright p {
  text-align: center;
  margin-bottom: 0;
  padding: 5px 0;
  font-size: 12px;
}
.mid-block{
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
}

.mid-block > div {
  height: 131px;
  width: 24%;
  margin: 10px;
  background: #fff;
  position: relative; 
  padding: 0;
  border-radius: 11px;
  transition: 0.5s;
} 
.mid-block .tab-view .tabs-block {
  height: 80%;
  box-shadow: 0px 0px 5px 0px #ddd;
  position: relative;
  display: block; 
  width: 100%;
  transition: 0.5s;
  border-radius: 11px;
}

.tab-view {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.tabs-block.active {
  /* transform: scale(1.2); */
  height: 131px !important;
}
.mid-block.align-item-top .tab-view {
  align-items: flex-start;
}
.mid-block .active img.faci-gif {
    display: block;
    width: 80px;
    filter: unset;
}
.mid-block > div img {
  width: 36px;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  filter: grayscale();
  top: 50%;
}
.mid-block > div img.faci-gif {
  display: none;
}

.news-blk img {
  width: 100%;
}
.innerslide {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background: linear-gradient(360deg, #1c1b3b8f 100%, #1c1b3b91 100%, #3333338c 100%) , url('https://webapi.entab.info/api/image/SMSUM/public/Images/innerslide.png') no-repeat center center;
  background-size: cover;
  height: 210px;
  padding-bottom: 50px;
}
 
.innersec {
  min-height: 400px;
  padding: 20px 10px 40px 10px;
}
.innersec p {
  line-height: 34px;
  font-size: 16px;
  color: #4e4e4e; 
}
.innersec h1 {
  font-family: "Oleo Script", system-ui;
  font-size: 35px; 
  position: relative; 
  background:-webkit-linear-gradient(#d6639b, #3c8fed);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 45px;
  padding-bottom: 0px;
  margin-bottom: 45px;
  padding-left: 15px;
}
.innersec h1:before {
  background: -webkit-linear-gradient(#d6639b, #3c8fed);
  content: " ";
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0;
  width: 5px;
}
.innersec h5 {
  font-size: 20px;
  color: #0664a8;
  line-height: 30px;
} 
 
ul.breadcrumbli {
  display: flex;
  margin-bottom: 50px;
  border-bottom: 0.5px solid #afafaf;
  padding: 4px 0;
}
ul.breadcrumbli li {
  position: relative;
  padding-left: 0;
  color: #000;
  list-style: none;
  font-size: 14px;
  padding-right: 20px;
}

ul.breadcrumbli li:before {
  position: absolute;
  content: ' / ';
  width: 100%;
  height: 100%;
  left: -13px;
  top: 0;
  color: #000;
}
ul.breadcrumbli li:first-child:before {
  content: '';
}
ul.breadcrumbli li a {
  color:#ff198b !important;
  position: relative;
  z-index: 9;
}

.msgbox {
  float: right;
  margin-bottom: 20px;
  margin-left: 30px;
  position: relative;
  width: 40%;
}
.msgbox img {
  border: 0px solid #bdbdbd;
  padding: 10px;
  position: relative;
  width: 100%;
  box-shadow: 0px 2px 10px 1px #ccc;
  border-radius: 5px;
}
.innersec .name span {
  color: #ff198b ;
  display: block;
  font-weight: 400;
}
.innersec .name {
  font-size: 16px;
  font-weight: 700;
}
.faclitibox h5 {
  font-family: "Oleo Script", system-ui;
  border-bottom: 1px dashed #5486bd;
  width: max-content;
  color: #214e8bc4;
}
.faclitibox h4 {
  background: linear-gradient(87deg, #29559838, transparent);
  width: max-content;
  padding: 15px 20px;
  font-size: 20px;
  font-family: cursive;
  margin-bottom: 25px;
  width: 100%;
  line-height: 30px;
}

ul.mainlist li {
  margin: 10px;
  line-height: 30px;
  font-size: 16px;
  list-style-type: none;
  position: relative;
  color: #464646;
  text-align: justify;
}
ul.mainlist li:before {
  content: "";
  font-size: 20px;
  color: #000;
  left: -5px;
  top: 6px;
  vertical-align: middle;
  margin-right: 12px;
  margin-left: -25px;
  position: absolute;
 background-image: url('https://webapi.entab.info/api/image/SMSUM/public/Images/list.png');  
  background-size: 100% 100%;
  height: 20px;
  width: 20px;
}
ul.mainlist {
  padding-left: 10px;
}
.schoolhours {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.schoolhours p {
  width: 48%;
  border-bottom: 1px solid #ccc;
  margin: 8px 10px;
  background: #f1f1f175;
  border-left: 5px solid #5477a6;
  padding: 5px 10px;
  box-shadow: 0px 1px 2px 1px #ccc;
}

.contactusbox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.contactusdesc {
  display: flex;
  color: #fff;
  font-size: 14px;
  line-height: 24px;
  align-items: center;
}

.contactusicn i {
  color: #fff;
  font-size: 20px;
}

.contactusicn {
  padding-right: 16px;
}
.contactusdiv {
  width: calc(35% - 30px);
  padding: 40px 30px;
  border-radius: 20px;
  margin: 0px 5px 20px 0px;
}
.fstcontact {
  background:#295599;
}

.scdcontact {
  background: #e865a6;
}

.thrdcontact {
  background: #f6a440;
}
.contactusdesc p {
  color: #fff;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
}
.contactusimg h4 { margin-top: 25px;margin-bottom: 20px;color: #fff;font-size: 20px;line-height: 30px;}
.tcblock {
  flex-wrap: wrap;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.tc {
  width: 49%;
  margin-bottom: 30px;
}

.tcblock iframe {
  width: 49%;
}

.tc label {
  color: #000;
  width: 100%;
  margin-bottom: 0;
}

.tc label p {
  margin-bottom: 0px;
}

.tc .form-group {
  margin-bottom: 20px;
}

.form-control:focus {
  box-shadow: none;
  border: 1px solid #ccc;
}

a.viewdetailsbtn {
  color: #fff;
  font-size: 14px;
}

.viewdetailsbtn {
  padding: 10px 15px;
  text-decoration: none;
  color: #fff;
  border-radius: 5px;
  margin-bottom: 20px;
  border: none;
  background: linear-gradient(45deg, #4f7add, #154275);
  transition: all 0.2s ease-in;
}

.viewdetailsbtn:hover {
  background: linear-gradient(45deg, #00082e, #00082e);
}
.contactusimg img {
  width: auto;
}

.erp_sec .guideheading {
  text-align: left;
}

.erp_sec h4 {
  text-align: center;
  line-height: 40px;
  font-size: 17px;
  margin-bottom: 0;
  margin-top: 15px;
  font-weight: 700;
  font-family: 'Roboto',sans-serif;
}

.assistance {
  background:rgb(230 243 255);
  padding: 13px;
  border: solid 1px #ddd;
  margin-top: 20px;
}
.erpbox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.erpboxdesc {
  width: 72%;
}

.erpboximg {
  width: 26%;
}

.erpboxdescpopup {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.erppopup {
  width: 30%;
  margin: 10px;
}

.imgheading a {
  color: #20488a;
  text-align: center;
  width: 100%;
  display: block;
  font-size: 20px;
  padding: 10px 0;
}

.erppopup .modal-body h5 {
  color: #000;
  text-align: center;
  padding-top: 20px;
}
 
.modal {
  background: #0000008a;
  z-index: 99999;
  padding-right: 0 !important;
}
h3.Subhead {
  color: #104677;
  font-size: 20px;
}

.magnify-modal {
  position: fixed !important;
  z-index: 999999999 !important;
  padding-top: 10px !important;
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  height: 100% !important;
  overflow: auto !important;
  background-color: rgba(0, 0, 0, 0.8509803922) !important
}

.magnify-header .magnify-toolbar {
  float: right;
  position: relative;
  z-index: 9
}

.magnify-stage {
  position: absolute;
  top: 40px;
  right: 10px;
  bottom: 40px;
  left: 10px;
  z-index: 1;
  background: #0000000a;
  overflow: hidden
}

.magnify-image {
  position: relative;
  display: inline-block;
  border: 5px solid #fff
}

.magnify-image.image-ready {
  max-width: 100%;
  max-height: 100%
}

.magnify-footer {
  position: absolute;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 40px;
  color: #fff;
  text-align: center
}

.magnify-footer .magnify-toolbar {
  display: inline-block
}

.magnify-button {
  display: inline-block;
  width: 40px;
  height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 10px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  border-width: 0;
  border-radius: 0;
  cursor: pointer;
  outline: none;
  background: #055a91;
}

.magnify-button:hover {
  color: #fff;
  background-color: #e00000
}

.magnify-button-close:hover {
  background-color: #e00000
}

.magnify-loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.3);
  color: #333
}

.magnify-loader::before {
  content: '';
  display: inline-block;
  position: relative;
  width: 36px;
  height: 36px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  -webkit-animation: magnifyLoading 1s infinite linear;
  animation: magnifyLoading 1s infinite linear
}

.magnify-loader::after {
  content: '';
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
  overflow: hidden
}

@-webkit-keyframes magnifyLoading {
  0% {
      -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
      transform: rotateZ(0deg) translate3d(0, 0, 0)
  }

  100% {
      -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
      transform: rotateZ(360deg) translate3d(0, 0, 0)
  }
}

@keyframes magnifyLoading {
  0% {
      -webkit-transform: rotateZ(0deg) translate3d(0, 0, 0);
      transform: rotateZ(0deg) translate3d(0, 0, 0)
  }

  100% {
      -webkit-transform: rotateZ(360deg) translate3d(0, 0, 0);
      transform: rotateZ(360deg) translate3d(0, 0, 0)
  }
}
.chooseyear {
  width: max-content;
  float: right;
  margin-bottom: 30px;
  margin-top: -45px;
}
    #chooseddlYear option { color: black;}
    .chooseyear  select{margin:0px;}
    .chooseyear  select{width:100%;background: unset;border: unset;height:28px; margin-top:15px;border: unset;border-bottom: 1px solid grey;}
    .clr{clear:both} 
    .video-gallery {
      padding: 20px;
      box-shadow: 0px 1px 4px 1px #03030330;
      margin-bottom: 20px;
      background: #e2edfc;
      border-radius: 3px;
  }
    .video-gallery a {
      display: flex;
      color: inherit;
      align-items: center;
    }
    .video-gallery a i {
     font-size: 40px;
     color: #ad0807;
     margin-right: 10px;
     display: flex;
    }
    .video-gallery a h3 {
      font-size: 16px;
      margin-bottom: 0;
      line-height: 24px;
      padding-top: 0;
      padding-bottom: 0;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      margin-top: 0;
      padding-left: 0;
  }
    .video-gallery p {
      font-size: 12px;
      margin-bottom: 0;
    }
    .video-gallery a h3:after {
      position: static;
    }
    .video-gallery a img {
      width: 52px;
      margin-right: 10px;
    }
    
.row.tabs-dynamic {
  background: #cbdcf1;
  padding: 20px;
  margin: 0px 0px 40px 0px;
}
.row.tabs-dynamic p {
  margin-bottom: 0;
}
.row.tabs-dynamic p a {
  color: #585757;
}
.row.tabs-dynamic select {
  width: 100%;
  border: 0px;
  background: transparent;
  padding: 8px 5px;
  border-bottom: 1px solid #b6b6b6;
}
.row.tabs-dynamic input {
  padding: 9px 5px;
  width: 100%;
  border: 0px;
  border-bottom: 1px solid #b6b6b6;
  background: transparent;
}
.count-val p {
  margin-bottom: 0px;
}
.load-more .next {
  background: #273c5b;
  width: max-content;
  padding: 5px 10px;
  color: #fff;
  border-radius: 5px;
  margin: auto;
  margin-top: 20px;
  cursor: pointer;
}

.innersec .news-head {display: flex;align-items: center;}
.innersec .news-head h2 {    padding-right: 20px; border-right: 1px solid #c5c5c5;  margin-right: 20px;  background: #e5e5e5; padding: 4px 10px;}
.innersec .news-head h3 {
  font-weight: 400;
  font-size: 22px;
  margin-top: 0;
  margin-bottom: 14px;
  color: #00599b;
  position: relative;
}
.innersec .news-blk {margin-bottom: 20px;padding-right: 30px;padding-bottom: 30px;width: 97%;box-shadow: 9px 11px 5px 0px #dddddd3d;position: relative;overflow: hidden;padding: 20px;box-shadow: 0px 0px 12px 0px #e7e7e7;border: 4px double #b5b5b5;transition: 0.5s;}
.innersec .news-blk:before {
  content: "";
  position: absolute;
  top: -40px;
  right: -8px;
  width: 100px;
  height: 100px;
  background: #ecf9ff;
  border-radius: 50%;
}
.innersec .attachment {
  background: #00bcd4;
  text-align: right;
  position: absolute;
  right: 0;
  bottom: 0;
  color: #fff;
  padding: 5px;
  }
  .news-content {
    min-height: 120px;
}
.innersec .news-content p {margin-bottom: 6px;max-height: 120px;overflow-y: scroll;}
.innersec .news-content p::-webkit-scrollbar {width: 0;}
.innersec .news-blk:hover {
  box-shadow: rgb(0 0 0 / 5%) 0px 2px 1px, rgb(0 0 0 / 5%) 0px 4px 2px, rgb(0 0 0 / 5%) 0px 8px 4px, rgb(0 0 0 / 5%) 0px 16px 8px, rgb(0 0 0 / 5%) 0px 32px 16px;
}
.innersec .news-head h2 span {
  display: block;
  color: #153168;
  font-size: 18px;
  text-align: center;
}
.newsCount:nth-child(4n+1) > div {
  border-left: 3px solid #fff27e;
}
.newsCount:nth-child(4n+2) > div {
  border-left: 3px solid #ceb2ff;
}
.newsCount:nth-child(4n+3) > div {
  border-left: 3px solid #ff928f;
}
.newsCount:nth-child(4n+4) > div {
  border-left: 3px solid #ffb470;
}
.eventbox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background: #f1f1f1bd;
  box-shadow: 2px 0px 8px 0px #ccc;
  margin-bottom: 20px;
}

.eventboximg {
  width: 40%;
  margin-right: 5px;
  position: relative;
}

.eventboxdesc {
  width: 58%;
}
.eventpgh {
  height: 125px;
  overflow-y: scroll;
  padding-top: 13px;
  padding-right: 2px;
}

.eventboximg img {
  border: 10px solid #f1f1f1bd;
  height: 165px;
  object-fit: contain;
  background: #fff;
  width: 100%;
}
.eventdate {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-between;
  padding: 8px 10px 8px 5px;
}
.eventpgh p {
  font-size: 15px;
  line-height: 30px;
}
.eventdate a {
  border-radius: 5px 3px 6px 0px;
  color: #fff;
  padding: 2px 5px;
}
.eventdate span {
  font-size: 13px;
  line-height: 23px;
  color: #1c1b3b;
}
.eventpgh h5 {
  color: #9e790c;
  line-height: 30px;
  font-size: 18px;
  font-family: "Inter", sans-serif;
}
.eventdate span i {
  color: #1c1b3b;
  position: relative;
  bottom: 3px;
}
.eventpgh::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5;
}
 
.eventpgh::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  background-color: #F5F5F5;
} 
.eventattch {
  background:#2b589d;
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px 8px 5px 5px;
  border-radius: 13px 0px 13px 0px;
  color: #fff;
}
.gallery-inner {
  background: #1c1b3b;
  margin-bottom: 30px;
  box-shadow: 7px 6px 12px -4px #ddd;
  /* position: relative; */
  overflow: hidden;
  transition: all 0.5s ease-in;
}
.gallery-blks a {
  color: inherit;
}
.gallery-blks img {
  width: auto;
  max-width: 100%;
  height: 90%;
  margin: auto;
  display: block;
  position: relative;
  box-shadow: 0px 0px 19px 0px #00000054;
  border: 7px solid #ffffff4f;
}
.gallery-title h3::after {
  position: static;
}
.gallery-inner:hover .gallery-content {
  bottom: 0;
}
.gallery-blks {
  height: 230px;
  object-fit: contain;
  position: relative;
  background: #ddd;
  overflow: hidden;
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
} 

.gallery-title p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  line-height: 28px !important;
  font-size: 16px !important;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
}

.gallery-date {
  display: flex;
}

  .gallery-date h6 {
      color: #fff;
      font-size: 16px;
      margin-bottom: 10px;
      line-height: 25px;
      background: #fc097c;
      padding: 5px 10px;
      border-radius: 5px;
      font-family: 'Poppins', sans-serif;
      margin-top: 0;
  }

.gall-num h5 {
  background: transparent;
  color: #000;
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 25px;
  padding: 5px 10px;
  border-radius: 5px;
  font-family: 'Poppins', sans-serif;
}
.galleryCount:nth-child(2n+2) .gallery-inner .gallery-date h6 {
  background: #f9d464;
}

.galleryCount:nth-child(2n+3) .gallery-inner .gallery-date h6 {
  background: #00acee;
}

.galleryCount:nth-child(2n+4) .gallery-inner .gallery-date h6 {
  background: #198754;
}

.innersec .gallery-block a {
  color: inherit;
}
.gallery-content {
  padding: 20px 15px;
  position: relative;
  background: #fff;
  width: 96%;
  margin: -30px auto 10px auto;
}
.gallery-content a.view-more {
  cursor: pointer;
}
.innersec .gallery-block img {
  width: auto;
  max-width: 100%;
  height: 90%;
  margin: auto;
  display: block;
  position: relative;
  box-shadow: 0px 0px 19px 0px #00000054;
  border: 7px solid #ffffff4f;
}

.gallery-inner:hover .gallery-content {
  bottom: 0;
}
.innersec .gallery-block {
  height: 230px;
  object-fit: contain;
  position: relative;
  background: #ddd;
  overflow: hidden;
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}
.sub-gallerycount .innersec .gallery-block img {
  height: 100%;
}
.sub-gallerycount .innersec .gallery-block {
  height: 200px;
  margin-bottom: 20px;
}
.innersec .gallery-block .gallery-title h3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
.gallery-title h3 {
  font-size: 18px;
  color: #b78c0b;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  padding: 0px;
  margin-bottom: 15px; 
  -webkit-background-clip: unset;
  -webkit-text-fill-color: unset;
  background: transparent;
}
h4.gallery-title {
  color: #000000bf;
  font-size: 20px;
  line-height: 30px;
  font-family: "Inter", sans-serif;
}
.sub-gallerycount img {
  width: 100%;
  margin-bottom: 20px;
  border: 3px double #ccc;
  padding: 3px;
  background: #4f7add1f;
  border-radius: 3px;
}

.campuslogo {
  position: fixed;
  bottom: 140px;
  right: 0;
  z-index: 2;
}
span.bounce1 img { width: 22px; }
.OnlineR a { color: #fff; }
a.parantlogo { padding: 2px 10px; }
.OnlineR { position: fixed; right: 0; z-index: 99; text-align: center; color: #fff; top: 40%; font-family: Open Sans, sans-serif; }
@keyframes bounce1 { 
  0% {
      background-color: #2367a5;
  }
  33% {
      background-color: #ffa207;
  }
  66% {
      background-color: #2367a5;
  }
  100% {
      background-color: #ffac10;
  }
}
.bounce1 { border-radius: 25px 0px 0px 25px; color: #fff; font-size: 14px; font-weight: 700; text-align: center; animation: bounce1 1s infinite; padding: 10px 12px; }

.popup {
  padding-right: 0 !important;
}
.popup .carousel-control-prev-icon { background: url('https://webapi.entab.info/api/image/SMSUM/public/Images/lefticn.png') no-repeat !important; }
.popup .carousel-control-next-icon { background: url('https://webapi.entab.info/api/image/SMSUM/public/Images/righticn.png') no-repeat !important; }
.popup .carousel-control-next, .popup .carousel-control-prev { width: auto; }
.popup .carousel-control-next { right: -15px; }
.popup .modal-dialog { min-width:50%; transform: translateY(-100px); }
.carousel-item .modal-header { background: #0a4685; padding: 13px 0 0 }
.popup .modal-dialog img { width: 100%; object-fit: contain; }
.carousel-item .modal-body h1 {padding-bottom: 0; text-align: center; font-size: 17px; color: #000; overflow: hidden; font-weight: 600; text-transform: capitalize; display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; margin-bottom: 10px; line-height: 28px; }
.carousel-item .modal-dialog .modal-header .btn-close { color: #fff; padding-right: 31px }
.carousel-item .modalcard .card-body { padding: 10px }
.carousel-item .modalcard .card-body p { display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden; line-height: 35px; text-align: center; padding-top: 10px; line-height: 26px; }
.carousel-item .modalcard { border: none }
.popup .modal-content { padding: 0 0 10px }
.carousel-item button.btn-close:focus { box-shadow: none }
.carousel-item a.btn.btn-primary.btn-sm {background: #09365f; border: #09365f; margin: 0 10px; color: #fff!important ;}
.carousel-item a.btn.btn-primary.btn-sm:focus { box-shadow: none; border: 1px solid #182b3c; }
.carousel-item button.btn-close {position: absolute;z-index: 999;background-color: #09365f;right: 0;}
.carousel-item .btn-close { background: url('https://webapi.entab.info/api/image/SMSUM/public/Images/closeicn.png') no-repeat; opacity: 1; padding: 10px; background-position: center; border-radius: 0; }
.modal-bottom { display: flex; flex-direction: row; justify-content: center; align-items: center; margin-top: 30px; }
.lazyload-wrapper {
  display: initial;
}
@media(max-width: 1600px) {
  .evt-title h3{
    font-size: 21px;
  }
  footer{padding-left: 0;}
  .header-contact .border-tab {
    font-size: 13px;
  } 

  .footer .d-flex {
    align-items: flex-end;
    margin-top: 00px;
  }

  .faci-blk {
    width: 98%;
    padding: 40px 10px;
    margin-bottom: 10px;
  }

  .topper-img {
 
    padding: 43px 20px;
  }
}


@media(max-width: 1400px) {
  .message-block .button { 
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .quick-link ul li {
    width: 60%;
}
  .footer {
    background: url(https://webapi.entab.info/api/image/SMSUM/public/Images/footer.png);;
    background-size: 100% auto;
    padding-top: 10px;
    background-position: bottom;
    background-repeat: no-repeat;
    width: 100%;
}
footer {
  background: #ffd9e4;
}
.footer iframe { 
  margin-top: 20px;
}
  .contact-tab > div {
    margin: 0 7px;
}
.quick-link ul {
  display: flex;
  flex-direction: column;
  height: 240px;
  flex-wrap: wrap;
} 
.quick-link ul {
  display: flex;
  flex-direction: column;
  height: 170px;
  flex-wrap: wrap;
}
.evt-img img {
  height: 260px;
  object-fit: contain;
  background: #ddd;
}
.contact-tab img { 
  width: 34px;
}
.header-blk { 
  width: 100%; 
} 
  .header-contact > div {
    width: 42%;
    font-size: 14px;
}
.logo img {
  width: 370px; 
}
.header-contact >div img {
height: 15px;
}

  .footer .link {
    font-size: 13px;
  }

  .evt-content {
    padding: 20px 6px 2px;
  }

  .evt-content p:last-child {
    font-size: 13px;
  }

  .topper-img img {
    width: 90px !important;
    margin: auto;
    height: 90px;
  }
}

@media(max-width: 1200px) {
  .vision-mission .mission, .vision-mission .vission, .vision-mission .motto, .vision-mission .highlight {

    min-height: 375px;
}
  .footer .d-flex {
    align-items: center;
  }
  .schoolhours p {
    width: 49%; 
    margin: 8px 4px;
  }
  .news { 
    margin-bottom: 40px;
}
  .announcement{
    display: none;
  }
  .sub-submenu{
    padding: 9px 12px;
  }
  .submenu li {
    color: #fff;
    margin: 0 0px;
    font-size: 14px;
}
.sub-submenu li {
  color: #000 !important;
}


  .patrons p {
    font-size: 20px;
  } 

  .min-ht {
    min-height: 370px;
  }

  .footer .d-flex {
    margin-top: 0;
  }

  .news {
    width: 65%;
    margin: auto; 
    border-radius: 6px;
  }
}

@media(max-width: 991px) {
  .vision-mission .mission, .vision-mission .vission, .vision-mission .motto, .vision-mission .highlight { 
    min-height: 300px;
}
  .vision-mission .col-md-6 {
    padding: 10px;
}
  .contactusdiv {
    width: calc(53% - 30px);
}
.contactusbox {
    justify-content: center;
}
  .schoolhours p {
    width: 48%;
  }
  .msgbox {
    width: 50%;
}
  .news{
    margin-bottom: 20px;
  }
  .events {
    width: 100%; 
}
.message {
  width: 90%;
  padding: 80px 0 20px;
  margin: auto;
}
  .vision-mission{
    width: 100%;
    margin: auto;
  }

  .vision-mission .mission, .vision-mission .vission, .vision-mission .motto, .vision-mission .highlight{
    width: 100%; 
  }
  .message-block{
    flex-direction: column-reverse;
  }
  .message-block > div:nth-child(1){
    width: 100%;
  }
  .message-block > div:nth-child(2) {
    width: 100%;
    margin: 0;
    object-fit: contain;
    height: 300px;
}

.message-image-first img {
  max-height: unset;
  height: 300px;
  width: 100%;
  object-fit: contain;
}
  .vision-mission .mission, .vision-mission .vission, .vision-mission .motto, .vision-mission .highlight { 
    margin-top: 20px; 
}
  .submenu li i.bi.bi-chevron-down{
    float: right;
  }
  .sub-submenu {
    position: static;
    margin-top: 16px;
}

.sub-submenu li {
    color: #000;
}
  .desk-only{
    display: none;
  }
  .header-contact{
    flex-wrap: wrap;
    padding-bottom: 0;
    margin-top: 0;
  } 

  .header-contact > div { 
    margin-bottom: 20px;
}
   
  .submenu {
    list-style-type: none;
    padding: 0;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    z-index: 1;
    display: block;
    border: none;
    width: 100%;
    flex-direction: column;
    padding: 20px 0;
    width: 98%;
    margin: 0 1%;
    box-shadow: -1px 1px 5px 0px #000000a1;
    z-index: 99;
  }

  .menu-toggle {
    display: block;
    background: #2b58a3;
    z-index: 9999;
    position: absolute;
    right: 20px;
    border-radius: 10px;
    top:-70px;
  }
  .submenu li{
      width: 100%;
      padding:5px 20px;
      border: 0;
  }
  .menu{
    position: relative;
  }
  .logo img {
    width: 100%;
  }
 
  
  .phone-img img {
    width: 52% !important;
  }

 

  .lg-mid {
    margin-bottom: 20px;
  }

  .news-area {
    margin-bottom: 20px;
  }
 

  .vision-mission:before {
    width: 90%;
    height: 2px;
  }

  

   

  .app-cont img {
    width: 120px !important;
  }

  .footer .link {
    text-align: center;
    font-size: 13px;
  }
}
 
@media(max-width: 767px) {

  .contact-tab { 
    margin-bottom: 20px;
}
.menu-toggle { 
    top: -170px;
}
  .erpboximg {
    width: 100%;
}

.erpboxdesc {
    width: 100%;
}
  .tcblock iframe {
    width: 100%;
}
.tc {
    width: 100%;
}
  .contactusdiv {
    width: 100%;
    padding: 20px 15px;
}
  .schoolhours p {
    width: 100%;
}

.msgbox {
  margin-top: 20px;
  width: 100%;
  float: none;
  margin-left: 0px;
}
  .gallery-blk img{
    height: auto;
  }
  .quick-link ul { 
    height: auto; 
} 
.logo img {
  width: 100%;
}
.message, .news-event>div{
  width: 99%; 
}
.footer{
  width: 99%;
  margin: 0px auto 0px;
}
  .about-us {
    margin-top: 0;
  }
  .popup .modal-dialog {
    min-width: 80%;
}
 
  .patrons p {
    font-size: 28px;
  }

  .patrons-cont {
    height: auto;
  }

  .min-ht {
    min-height: 290px;
  }

  .news {
    padding-bottom: 20px;
  }

  .topper-img {
    background-size: 67% 86%;
    padding: 43px 20px;
  }

  .vision-mission {
    position: relative;
    top: 0;
    width: 96%;
  }

  .tpr-block {
    padding-left: 30px;
  }

  .topper-img img {
    width: 110px !important;
    height: 110px;
  }
}

@media(max-width:600px) {
 .logo img { 
      margin-left: 0;
  }
  .vision-mission h3,
  .title h3 {
    font-size: 24px;
  }

  .patrons p {
    font-size: 22px;
  }

  .phone-img img {
    width: 100% !important;
  }

  .topper-img {
    background-size: 50% 86%;
  }
 

  .vision-mission {
    position: static;
    width: 95%;
    padding: 10px;
  }

  p {
    font-size: 14px;
  }

  .news-bg {
    background: unset;
  }

  .news {
    width: 100%;
    margin: auto;
    padding: 30px 30px 0px;
    border-radius: 6px;
    
    padding-bottom: 10px;
    margin-bottom: 20px;
  }

  .events {
    width: 100%
  }

  .news-blk {
    margin-bottom: 20px;
  }

  .min-ht {
    min-height: 190px;
  }
   
}
@media(max-width: 576px) {
  .facilities {
    width: 100%;
  }
.eventboximg {
  width: 100%;
}
.eventboxdesc {
  width: 100%;
  padding: 0px 10px;
}
}
@media(max-width: 400px) {
  ul.breadcrumbli {
    flex-wrap: wrap;
  }
  .ph-only {
    display: none;
}
.menu {
  height: auto;
  float: unset;
}

.menu-toggle {
  position: static;
  width: 100%;
  padding: 2px;
  border-radius: 0;
  text-align: right;
  padding: 2px 11px;
}

.header-contact > div {
    margin-bottom: 0 !important;
}
.header-blk {
  padding: 8px 8px 20px;
}
  .app-cont,
  .phone-img {
    width: 100%;
  }

  .min-ht {
    min-height: 120px;
  }

  
}